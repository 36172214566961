'use strict'

import bwipjs from 'bwip-js'

const _confirmParams = {
  title: 'Confirmation',
  buttonSize: 'sm',
  okTitle: 'Oui',
  okVariant: 'danger',
  cancelTitle: 'Annuler',
  cancelVariant: 'light',
  headerClass: 'pt-2 pb-1',
  footerClass: 'pt-0 pb-1',
  centered: true,
  autoFocusButton: 'ok'
}

const _getBarcodeImg = (barcode, type, options) => {
  try {
    let canvas = document.createElement('canvas')
    bwipjs.toCanvas(canvas, Object.assign({
      bcid: type || 'code128',
      text: barcode,
      scaleX: 2,
      height: 10,
      includetext: false,
      textsize: 4,
      textxalign: 'center'
    }, options || {}))
    return canvas.toDataURL('image/png')
  } catch (e) {
    // console.log(e)
    return null
  }
}

const _getQrcodeImg = (qrcode, type, options) => {
  try {
    let canvas = document.createElement('canvas')
    bwipjs.toCanvas(canvas, Object.assign({
      bcid: type || 'qrcode',
      text: qrcode,
      scale: 2,
      includetext: false,
      textsize: 4,
      textxalign: 'center'
    }, options || {}))
    return canvas.toDataURL('image/png')
  } catch (e) {
    // console.log(e)
    return null
  }
}

export default {
  confirmParams: _confirmParams,
  getBarcodeImg: _getBarcodeImg,
  getQrcodeImg: _getQrcodeImg
}
